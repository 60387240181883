.App {
  text-align: center;
}

.container {
  max-width: 860px !important;
}

.main-content {
  margin-top: 20px;
  border: 1px solid #e0e0e0;
}

.break-word {
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

